<template>
    <div>
        <h4 class="mb-2">{{$t('legalInfo.companyInfo')}}</h4>
        <!-- <detail-item
            v-if="legalInfo.auditionHistory"
            :title="$t('legalInfo.auditionHistory')"
            :content="legalInfo.auditionHistory ? $t('app.yes') : $t('app.no')"
        /> -->
        <div
            v-if="legalInfo.companyControlled"
        >
            <detail-item
                :title="$t('clientSessions.detail.legalInfo.companyInfo.auditionHistory')"
                :content="legalInfo.companyControlledName"
            />
            <detail-item
                :title="$t('clientSessions.detail.legalInfo.companyInfo.companyControlledAddress')"
                :content="legalInfo.companyControlledAddress"
            />

            <uploads-list
                v-if="companyUploads.length > 0"
                class="mt-3"
                :uploads="companyUploads"
            />
        </div>
    </div>
</template>
<script>
import UploadsList from './UploadsList.vue'
export default {
    name: 'LegalInfoCompanyInfo',
    components: {
        UploadsList
    },
    props: {
        legalInfo: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    computed: {
        companyUploads () {
            const companyUploads = (this.legalInfo?.uploads ?? []).filter(item => item.meta === 'companyControlled').map(item => (item.upload ?? item))
            return this.uploads.filter(upload => companyUploads.includes(upload._id))
        }
    }
}
</script>
