<template>
    <div class="table-responsive">
        <table
            class="table table-bordered table-sm"
        >
            <thead>
                <tr class="table-warning">
                    <th colspan="2">
                        {{$t(`aml.header.${(isPerson) ? 'otherInfo' : 'otherCriteria'}`)}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>{{$t('aml.contractReason')}}</th>
                    <td>{{legalInfo.reason}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.amount')}}</th>
                    <td>{{legalInfo.transactionsAmount}}</td>
                </tr>
                <tr>
                    <th>{{$t('aml.interval')}}</th>
                    <td>{{$t(`aml.${legalInfo.transactionsInterval}`)}}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <p>
                            <strong>
                                {{$t('aml.moneyOrigin')}}
                            </strong>
                        </p>
                        <contract-checkbox
                            :state="legalInfo.socialBenefits"
                            :text="$t('contract.contractContent.resources.socialBenefits')"
                        />
                        <contract-checkbox
                            :state="legalInfo.wage"
                            :text="$t('contract.contractContent.resources.wage')"
                        >
                            <span class="resources-response" v-if="legalInfo.wage">{{legalInfo.employment}}</span>
                        </contract-checkbox>
                        <contract-checkbox
                            :state="legalInfo.business"
                            :text="$t('contract.contractContent.resources.business')"
                        >
                            <span class="resources-response" v-if="legalInfo.business">{{legalInfo.businessField}}</span>
                        </contract-checkbox>
                        <contract-checkbox
                            :state="legalInfo.capital"
                            :text="$t('contract.contractContent.resources.capital')"
                        >
                            <span class="resources-response" v-if="legalInfo.capital">{{legalInfo.capitalResources}}</span>
                        </contract-checkbox>
                        <contract-checkbox
                            :state="legalInfo.other"
                            :text="$t('contract.contractContent.resources.other')"
                        >
                            <span class="resources-response" v-if="legalInfo.other">{{legalInfo.otherResources}}</span>
                        </contract-checkbox>
                    </td>
                </tr>
                <tr v-if="clientType === 'PO'">
                    <th>
                        {{$t('aml.companyControlled')}}
                    </th>
                    <td>
                        {{(legalInfo.companyControlled) ? $t('aml.yes') : $t('aml.no')}}
                    </td>
                </tr>
                <tr v-if="legalInfo.companyControlled && isCompany">
                    <th>
                        {{$t('aml.companyControlledName')}}
                    </th>
                    <td>
                        {{legalInfo.companyControlledName}}
                    </td>
                </tr>
                <tr v-if="legalInfo.companyControlled">
                    <th>
                        {{$t('aml.companyControlledAddress')}}
                    </th>
                    <td>
                        {{legalInfo.companyControlledAddress}}
                    </td>
                </tr>
                <!-- <tr v-if="clientType !== 1">
                    <th>
                        {{$t('aml.auditionHistory')}}
                    </th>
                    <td>
                        {{(legalInfo.auditionHistory) ? $t('aml.yes') : $t('aml.no')}}
                    </td>
                </tr> -->
                <!-- <tr>
                    <th>
                        {{$t('aml.sanctionedBusiness')}}
                    </th>
                    <td>
                        {{(!legalInfo.sanctionedBusiness) ? $t('aml.yes') : $t('aml.no')}}
                    </td>
                </tr> -->
                <!-- <tr>
                    <th>
                        {{$t('aml.criminalActivity')}}
                    </th>
                    <td>
                        {{(legalInfo.criminalActivity) ? $t('aml.yes') : $t('aml.no')}}
                    </td>
                </tr> -->
                <tr v-if="clientType === 'FO'">
                    <th>
                        {{$t('aml.politician')}}
                    </th>
                    <td>
                        {{(basicData.politician) ? $t('aml.yes') : $t('aml.no')}}
                    </td>
                </tr>
            </tbody>
        </table>
        <slot></slot>
    </div>
</template>

<script>
import ContractCheckbox from './ContractCheckbox.vue'

export default {
    name: 'AmlCriteria',
    components: {
        ContractCheckbox
    },
    props: {
        legalInfo: {
            type: Object,
            required: true
        },
        clientType: {
            type: String,
            required: true
        },
        basicData: {
            type: Object,
            required: true
        }
    },
    computed: {
        isCompany () {
            return this.clientType === 'PO'
        },
        isPerson () {
            return this.clientType === 'FO'
        },
        isSelfEmployed () {
            return this.clientType === 'OS'
        },
        reason () {
            return this.legalInfo.reason
        }
    }
}
</script>
