<template>
    <div>
        <h2 class="text-center">{{(isPerson) ? $t('aml.titlePerson') : $t('aml.titleCompany')}}</h2>
        <fx-header
            :aml="true"
        />

        <aml-client-header
            v-if="!isPerson"
            :client-type="clientType"
            :basic-data="basicData"
            :owners="owners"
            :personal-id="personalId"
        />

        <aml-client-person-header
            v-else
            :client-type="clientType"
            :basic-data="basicData"
            :owners="owners"
            :personal-id="personalId"
        />

        <aml-represented-by
            :client-type="clientType"
            :basic-data="basicData"
            :brokers="brokers"
            :owners="owners"
            :personal-id="personalId"
        />

        <aml-real-owners
            v-if="isCompany"
            :real-owners="realOwners"
            :real-owner-undeterminable="realOwnerUndeterminable"
        >
            <p class="mt-2">
                {{ $t('aml.text.pep') }}
            </p>
            <real-owner-text />
        </aml-real-owners>

        <aml-criteria
            class="mt-4"
            :legal-info="legalInfo"
            :client-type="clientType"
            :basic-data="basicData"
        >
            <contract-par
                v-if="isPerson"
                number=""
                :text="$t('aml.text.pep')"
            />
        </aml-criteria>

        <p><em>{{$t('aml.text.note')}}</em></p>
        <p>{{$t('aml.text.par1')}}</p>
        <p>{{$t('aml.text.par2')}}</p>
        <p>{{$t('aml.text.par3')}}</p>
        <p>{{$t('aml.text.par4')}}</p>
        <p>{{$t('aml.text.par5')}}</p>
    </div>
</template>

<script>
import ContractBase from './ContractBase.vue'
import AmlClientHeader from './AmlClientHeader.vue'
import AmlRepresentedBy from './AmlRepresentedBy.vue'
// import AmlStatutary from './AmlStatutary.vue'
import AmlRealOwners from './AmlRealOwners.vue'
import AmlCriteria from './AmlCriteria.vue'
import AmlClientPersonHeader from './AmlClientPersonHeader.vue'
import ContractPar from './ContractPar.vue'
import RealOwnerText from '../../../RealOwners/Components/RealOwnerText.vue'

export default {
    name: 'AmlContract',
    extends: ContractBase,
    components: {
        AmlClientHeader,
        AmlRepresentedBy,
        AmlRealOwners,
        AmlCriteria,
        AmlClientPersonHeader,
        ContractPar,
        RealOwnerText
    }
}
</script>
