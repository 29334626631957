<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm contract-table contract-table-client">
            <thead>
                <tr class="table-warning">
                    <th colspan="6">
                        {{$t('aml.header.serviceRecipient')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.name')}}
                    </th>
                    <td colspan="2">
                        {{basicData.name}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.surname')}}
                    </th>
                    <td colspan="2">
                        {{basicData.surname}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.birthDate')}}
                    </th>
                    <td colspan="2">
                        {{new Date(basicData.birthDate).toLocaleDateString()}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.rc')}}
                    </th>
                    <td colspan="2">
                        {{(basicData.noRc) ? '---' : basicData.rc}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.address')}}
                    </th>
                    <td colspan="2">
                        {{`${basicData.addressStreet}, ${basicData.addressZip}, ${basicData.addressCity}`}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.postAddress')}}
                    </th>
                    <td colspan="2">
                        {{((basicData.postAddressActive) ? `${basicData.postAddressStreet}, ${basicData.postAddressZip}, ${basicData.postAddressCity}` : '---')}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.countryOrigin')}}
                    </th>
                    <td colspan="2">
                        {{basicData.countryOrigin}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.country')}}
                    </th>
                    <td colspan="2">
                        {{$t(`countries.${basicData.addressCountry}`)}}
                    </td>
                </tr>
                <tr>
                    <th>
                        {{$t('aml.clientHeader.personalIdNumber')}}
                    </th>
                    <td>
                        {{personalId.documentNumber}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.personalIdValidity')}}
                    </th>
                    <td>
                        {{new Date(personalId.documentValidity).toLocaleDateString()}}
                    </td>
                    <th>
                        {{$t('aml.clientHeader.personalIdIssuedBy')}}
                    </th>
                    <td>
                        {{personalId.documentIssuedBy}}
                    </td>
                </tr>
            </tbody>
        </table>
        <i18n path="basicData.countryOriginInfoText.other.text"
            class="mt-2"
            tag="p"
        >
            <strong>{{ $t('basicData.countryOriginInfoText.other.text1Strong') }}</strong>
            <strong>{{ $t('basicData.countryOriginInfoText.other.text2Strong') }}</strong>
            <strong>{{ $t('basicData.countryOriginInfoText.other.text3Strong') }}</strong>
            <strong>{{ $t('basicData.countryOriginInfoText.other.text4Strong') }}</strong>
        </i18n>
    </div>
</template>

<script>
import ClientHeader from './ClientHeader.vue'

export default {
    name: 'AmlClienPersontHeader',
    extends: ClientHeader
}
</script>
