<template>
  <b-form
    @submit.prevent="onSubmit"
  >
    <h3 class="mt-4">
        <span v-if="clientType === 'PO'">{{$t('basicData.headings.basicInfoCompany')}}</span>
        <span v-else>{{$t('basicData.headings.basicInfo')}}</span>
    </h3>
    <hr />

    <div class="row" v-if="clientType !== 'PO'">
        <div class="col-12 col-md-2">
            <form-input
                :label="$t('basicData.title')"
                v-model="title"
                :validation="$v.title"
            />
        </div>
        <div class="col-12 col-md-5">
            <form-input
                :label="$t('basicData.name')"
                v-model="name"
                :validation="$v.name"
            />
        </div>
        <div class="col-12 col-md-5">
            <form-input
                :label="$t('basicData.surname')"
                v-model="surname"
                :validation="$v.surname"
            />
        </div>
    </div>

    <div v-if="clientType !== 'FO'">
        <form-input
            v-if="clientType === 'PO'"
            :label="$t('basicData.companyName')"
            v-model="companyName"
            :validation="$v.companyName"
        />
        <form-input
            :label="clientType === 'PO' ? $t('basicData.companyScopePO') : $t('basicData.companyScopeOS')"
            v-model="companyScope"
            :validation="$v.companyScope"
        />
        <form-input
            :label="$t('basicData.companyRealScope')"
            v-model="companyRealScope"
            :validation="$v.companyRealScope"
        />
        <div class="row">
            <div class="col-12 col-md-6">
               <form-input
                    :label="$t('basicData.companyForm')"
                    v-model="companyForm"
                    :validation="$v.companyForm"
                />
            </div>
            <div class="col-12 col-md-6">
                <form-input-select
                    :label="$t('basicData.companyRiskActivity')"
                    v-model="companyRiskActivity"
                    :options="riskActivities"
                    :validation="$v.companyRiskActivity"
                />
            </div>
        </div>
    </div>

    <div class="row" v-if="clientType !== 'FO'">
        <div class="col-12 col-md-6">
            <form-input
                :label="$t('basicData.ic')"
                v-model="ic"
                :validation="$v.ic"
            />
        </div>
        <div class="col-12 col-md-6">
            <form-input
                :label="$t('basicData.vat')"
                v-model="vat"
            />
        </div>
    </div>

    <form-input-select
        :label="$t('basicData.domicile')"
        v-model="domicile"
        :options="countries"
        :validation="$v.domicile"
    />

    <form-input-checkbox
        v-if="clientType !== 'PO'"
        v-model="politician"
    >
        <template>
            {{ $t('basicData.politician') }}
            <b-icon id="infoPopover" @click.prevent.stop="showPopover = !showPopover" icon="info-circle" class="ml-1"/>
        </template>
    </form-input-checkbox>

    <b-popover
        v-if="clientType !== 'PO'"
        :show.sync="showPopover"
        :custom-class="'popover-large'"
        target="infoPopover"
        :triggers="['hover', 'focus']"
        placement="bottom"
    >
        {{ $t('basicData.politicianInfo') }}
    </b-popover>

    <h3 class="mt-4">
        {{$t('basicData.headings.address')}}
    </h3>
    <hr />

    <form-input
        :label="$t('basicData.addressStreet')"
        v-model="addressStreet"
        :validation="$v.addressStreet"
    />

    <div class="row">
        <div class="col-12 col-sm-6">
            <form-input
                :label="$t('basicData.addressCity')"
                v-model="addressCity"
                :validation="$v.addressCity"
            />
        </div>
        <div class="col-12 col-sm-6">
            <form-input
                :label="$t('basicData.addressZip')"
                v-model="addressZip"
                :validation="$v.addressZip"
                :validation-messages="{ custom: $t('validation.invalidZipLength') }"
            />
        </div>
    </div>

    <form-input-select
        :label="$t('basicData.addressCountry')"
        v-model="addressCountry"
        :options="countries"
        :validation="$v.addressCountry"
    />

    <div class="row" v-show="clientType !== 'PO'">
        <div class="col-12 col-sm-6">
            <form-input
                :disabled="noRc"
                :label="$t('basicData.rc')"
                v-model="rc"
                :validation="$v.rc"
                :formatter="birthNumberFormatter"
                :validation-messages="{ custom: (`${rc}`.length === 11) ? $t('validation.invalidRCControlNumber') : $t('validation.invalidRCLength') }"
                @input="onBirthNumberInput"
            />
        </div>
        <div class="col-12 col-sm-6">
            <form-input-date-picker
                :label="$t('basicData.birthDate')"
                v-model="birthDate"
                :hint="birthDateHint"
                :max-date="new Date()"
                :validation="$v.birthDate"
                :locale="datePickerLang"
            >
                <template #input-text>
                    <a href="javascript:void(0)"
                        @click.prevent="onSetBirthDate"
                    >
                        <small>{{$t('basicData.setBirthDateFromRC')}}</small>
                    </a>
                </template>
            </form-input-date-picker>
        </div>
    </div>

    <form-input-checkbox
        v-if="clientType !== 'PO'"
        :label="$t('basicData.noRc')"
        v-model="noRc"
    />

    <form-input-checkbox
        :label="$t('basicData.postAddressActive')"
        v-model="postAddressActive"
    />

    <div v-if="postAddressActive">
        <h3 class="mt-4">{{ $t('basicData.headings.postAddress') }}</h3>
        <hr/>
        <form-input
            :label="$t('basicData.postAddressName')"
            v-model="postAddressName"
        />
        <form-input
            :label="$t('basicData.postAddressStreet')"
            v-model="postAddressStreet"
            :validation="$v.postAddressStreet"
        />
        <div class="row">
            <div class="col-12 col-sm-6">
                <form-input
                    :label="$t('basicData.postAddressCity')"
                    v-model="postAddressCity"
                    :validation="$v.postAddressCity"
                />
            </div>
            <div class="col-12 col-sm-6">
                <form-input
                    :label="$t('basicData.postAddressZip')"
                    v-model="postAddressZip"
                    :validation="$v.postAddressZip"
                    :validation-messages="{ custom: $t('validation.invalidZipLength') }"
                />
            </div>
        </div>

        <form-input-select
            :label="$t('basicData.postAddressCountry')"
            v-model="postAddressCountry"
            :options="countries"
            :validation="$v.postAddressCountry"
        />
    </div>

    <h3 class="mt-4">{{ $t('basicData.headings.contactInfo') }}</h3>
    <hr/>
    <form-input
        :label="$t('basicData.email')"
        v-model="email"
        :validation="$v.email"
        :hint="$t('basicData.desc.email')"
    />

    <form-input
        :label="$t('basicData.phone')"
        :hint="$t('basicData.desc.phone')"
        v-model="phoneNumberComputed"
        :validation="$v.phone"
        :formatter="phoneFormatter"
    />

    <form-input
        :label="$t('basicData.phonePassword')"
        v-model="phonePassword"
        :validation="$v.phonePassword"
        :hint="$t('basicData.desc.phonePassword')"
    />

    <h3 class="mt-4">{{ $t('basicData.headings.otherInfo') }}</h3>
    <hr/>
    <form-input
        :label="$t('basicData.countryOrigin')"
        v-model="countryOrigin"
        :validation="$v.countryOrigin"
    />

    <i18n path="basicData.countryOriginInfoText.other.text"
        v-if="clientType === 'FO'"
        class="alert alert-primary small"
        tag="p"
    >
        <strong>{{ $t('basicData.countryOriginInfoText.other.text1Strong') }}</strong>
        <strong>{{ $t('basicData.countryOriginInfoText.other.text2Strong') }}</strong>
        <strong>{{ $t('basicData.countryOriginInfoText.other.text3Strong') }}</strong>
        <strong>{{ $t('basicData.countryOriginInfoText.other.text4Strong') }}</strong>
    </i18n>

    <div
        v-else
        class="alert alert-primary small"
    >
        <i18n path="basicData.countryOriginInfoText.person.textStart" class="mb-2" tag="p">
            <strong>{{ $t('basicData.countryOriginInfoText.person.textStartStrong') }}</strong>
        </i18n>
        <ol class="pl-3">
            <i18n path="basicData.countryOriginInfoText.person.text1" class="mb-1" tag="li">
                <strong>{{ $t('basicData.countryOriginInfoText.person.text1Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.person.tex11Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.person.text12Strong') }}</strong>
                <strong>{{ $t('basicData.countryOriginInfoText.person.text13Strong') }}</strong>
            </i18n>
            <i18n path="basicData.countryOriginInfoText.person.text2" class="mb-1" tag="li">
                <strong>{{ $t('basicData.countryOriginInfoText.person.text2Strong') }}</strong>
            </i18n>
            <i18n path="basicData.countryOriginInfoText.person.text3" class="mb-1" tag="li">
                <strong>{{ $t('basicData.countryOriginInfoText.person.text3Strong') }}</strong>
            </i18n>
            <i18n path="basicData.countryOriginInfoText.person.text4" tag="li">
                <strong>{{ $t('basicData.countryOriginInfoText.person.text4Strong') }}</strong>
            </i18n>
        </ol>
    </div>

    <div class="mt-5">
        <b-btn
            type="submit"
            variant="primary"
            class="mr-4 mb-2"
        >
            {{$t('app.submit')}}
        </b-btn>

        <b-btn
            variant="light"
            @click.prevent="onCancel"
            class="mb-2"
        >
            {{$t('app.cancel')}}
        </b-btn>
    </div>
  </b-form>

</template>

<script>
import { email, maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import { mod11_10 as mod1110 } from 'cdigit'
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js'
import * as objectHash from 'object-hash'

export default {
    name: 'BasicDataForm',
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        clientType: {
            required: true
        },
        countries: {
            type: Array,
            required: false,
            default: () => []
        },
        riskActivities: {
            type: Array,
            required: false,
            default: () => []
        },
        datePickerLang: {
            type: String,
            required: false,
            default: 'cz'
        }
    },
    data () {
        return {
            title: this.defaults.title || null,
            name: this.defaults.name || null,
            surname: this.defaults.surname || null,
            domicile: this.defaults.domicile || null,
            politician: this.defaults.politician || false,
            addressStreet: this.defaults.addressStreet || null,
            addressCity: this.defaults.addressCity || null,
            addressZip: this.defaults.addressZip || null,
            addressCountry: this.defaults.addressCountry || null,
            rc: this.defaults.rc || null,
            birthDate: this.defaults.birthDate || null,
            noRc: this.defaults.noRc || false,
            postAddressActive: this.defaults.postAddressActive || false,
            postAddressName: this.defaults.postAddressName || null,
            postAddressStreet: this.defaults.postAddressStreet || null,
            postAddressCity: this.defaults.postAddressCity || null,
            postAddressZip: this.defaults.postAddressZip || null,
            postAddressCountry: this.defaults.postAddressCountry || null,
            email: this.defaults.email || null,
            phone: this.defaults.phone || null,
            phonePassword: this.defaults.phonePassword || null,
            companyName: this.defaults.companyName || null,
            companyScope: this.defaults.companyScope || null,
            companyRealScope: this.defaults.companyRealScope || null,
            companyForm: this.defaults.companyForm || null,
            companyRiskActivity: this.defaults.companyRiskActivity || null,
            ic: this.defaults.ic || null,
            vat: this.defaults.vat || null,
            countryOrigin: this.defaults.countryOrigin || null,
            showPopover: false
        }
    },
    computed: {
        dataHash () {
            return objectHash.sha1(JSON.parse(JSON.stringify(this.$data)))
        },
        birthDateHint () {
            if (this.birthDateFromBirthNumber() instanceof Date && this.birthDate !== null) {
                const fixedDate = new Date(this.birthDate.valueOf())
                fixedDate.setHours(12, 0, 0, 0)
                if (!this.noRc && fixedDate.valueOf() !== this.birthDateFromBirthNumber().valueOf()) {
                    return `${this.$t('validation.invalidBirthDateFromRC')} ${this.birthDateFromBirthNumber().toLocaleDateString()}`
                }
            }
            return undefined
        },
        minBirthDate () {
            const minDate = new Date()
            minDate.setHours(12, 0, 0, 0)
            minDate.setFullYear(minDate.getFullYear() - 18)
            return minDate
        },
        phoneNumberComputed: {
            get () {
                return this.phone
            },
            set (value) {
                const number = this.validatePhoneNumber(value)
                this.phone = (number !== null) ? number : value
            }
        }
    },
    watch: {
        defaults () {
            this.$nextTick(() => {
                this.title = this.defaults.title || null
                this.name = this.defaults.name || null
                this.surname = this.defaults.surname || null
                this.domicile = this.defaults.domicile || null
                this.politician = this.defaults.politician || false
                this.addressStreet = this.defaults.addressStreet || null
                this.addressCity = this.defaults.addressCity || null
                this.addressZip = this.defaults.addressZip || null
                this.addressCountry = this.defaults.addressCountry || null
                this.rc = this.defaults.rc || null
                this.birthDate = this.defaults.birthDate || null
                this.noRc = this.defaults.noRc || false
                this.postAddressActive = this.defaults.postAddressActive || false
                this.postAddressName = this.defaults.postAddressName || null
                this.postAddressStreet = this.defaults.postAddressStreet || null
                this.postAddressCity = this.defaults.postAddressCity || null
                this.postAddressZip = this.defaults.postAddressZip || null
                this.postAddressCountry = this.defaults.postAddressCountry || null
                this.email = this.defaults.email || null
                this.phone = this.defaults.phone || null
                this.phonePassword = this.defaults.phonePassword || null
                this.companyName = this.defaults.companyName || null
                this.companyScope = this.defaults.companyScope || null
                this.companyRealScope = this.defaults.companyRealScope || null
                this.companyForm = this.defaults.companyForm || null
                this.companyRiskActivity = this.defaults.companyRiskActivity || null
                this.ic = this.defaults.ic || null
                this.vat = this.defaults.vat || null
                this.countryOrigin = this.defaults.countryOrigin || null
            })
        },
        dataHash (newValue) {
            this.$store.commit('setFormData', { name: 'basicData', content: this.$data })
            this.$store.commit('setDataHash', { name: 'basicData', hash: newValue })
        }
    },
    mounted () {
        this.$serverValidator.addHandler('basicData', this.onServerValidation)
        if (Object.keys(this.defaults).length > 0) {
            const calculatedHash = objectHash.sha1(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('setDataHash', { name: 'basicDataOld', hash: calculatedHash })
            this.$store.commit('setDataHash', { name: 'basicData', hash: null })
            this.$store.commit('setFormData', { name: 'basicData', content: this.$data })
        }
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('basicData', this.onServerValidation)
    },
    methods: {
        onServerValidation (isValid) {
            if (!isValid) {
                this.$v.$touch()
                this.$notify.error(this.$t('errors.someDataIsMissing'))
            }
        },
        onCancel () {
            this.$store.commit('setDataHash', { name: 'basicDataOld', hash: null })
            this.$store.commit('setDataHash', { name: 'basicData', hash: null })
            this.$emit('cancel')
        },
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    this.$emit('submit', JSON.parse(JSON.stringify(this.$data)))
                }
            })
        },
        phoneFormatter (number) {
            if (number) {
                return parseIncompletePhoneNumber(number)
            }
            return number
        },
        validatePhoneNumber (value) {
            const phoneNumber = parsePhoneNumberFromString(`${value}`)
            if (phoneNumber && phoneNumber.nationalNumber.length >= 9) {
                return phoneNumber.format('E.164')
            }
            return null
        },
        onBirthNumberInput () {
            const numericValue = `${this.rc}`.replace(/\D/ig, '')
            if (numericValue.length === 11 && mod1110.validate(numericValue)) {
                const date = new Date()
                date.setHours(12, 0, 0, 0)
                this.birthDate = date
                this.addressCountry = 'HR'
                this.$v.birthDate.$touch()
            }

            if (!this.$v.rc.$invalid && (this.birthDateFromBirthNumber() instanceof Date)) {
                this.onSetBirthDate()
            }
        },
        birthNumberFormatter (value) {
            return (`${value}`.replace(/\D/ig, '').length === 11) ? `${value}`.replace(/\D/ig, '') : this.$stringFormat.birthNumber(value)
        },
        onSetBirthDate () {
            if (this.birthDateFromBirthNumber() instanceof Date) {
                const date = new Date(this.birthDateFromBirthNumber().valueOf())
                date.setHours(12, 0, 0, 0)
                this.birthDate = date
                this.$v.birthDate.$touch()
            }
        },
        birthDateFromBirthNumber () {
            if (this.$v.rc.$invalid) {
                return null
            }
            if (`${this.rc}`.replace(/\D/ig, '').length > 10) {
                return null
            }
            const birthNumber = `${this.rc}`
            const base = birthNumber.slice(0, 6)
            let year = parseInt(base.slice(0, 2))
            if (birthNumber.length === 11 && year < 54) {
                year += 2000
            } else {
                year += 1900
            }
            let month = parseInt(base.slice(2, 4))
            if (month > 12) {
                month -= 50
            }
            month -= 1
            const day = parseInt(base.slice(4, 6))
            const birthDate = new Date(year, month, day, 12, 0, 0, 0)
            return birthDate
        }
    },
    validations () {
        const _this = this
        return {
            title: {
                maxLength: maxLength(10)
            },
            name: {
                required: requiredIf(() => {
                    return _this.clientType !== 'PO'
                })
            },
            surname: {
                required: requiredIf(() => {
                    return _this.clientType !== 'PO'
                })
            },
            domicile: {
                required
            },
            addressStreet: {
                required
            },
            addressCity: {
                required
            },
            addressZip: {
                required,
                custom: function (value) {
                    return value && `${value}`.replace(/\s/g, '').length >= 5
                },
                maxLength: maxLength(7)
            },
            addressCountry: {
                required
            },
            postAddressStreet: {
                required: requiredIf((model) => {
                    return _this.postAddressActive
                })
            },
            postAddressCity: {
                required: requiredIf((model) => {
                    return _this.postAddressActive
                })
            },
            postAddressZip: {
                required: requiredIf((model) => {
                    return _this.postAddressActive
                }),
                custom: function (value) {
                    if (!_this.postAddressActive) {
                        return true
                    }
                    return value && `${value}`.replace(/\s/g, '').length >= 5
                },
                maxLength: maxLength(7)
            },
            postAddressCountry: {
                required: requiredIf((model) => {
                    return _this.postAddressActive
                })
            },
            rc: {
                required: requiredIf(() => {
                    return !_this.noRc && _this.clientType !== 'PO'
                }),
                custom (value) {
                    if (_this.clientType === 'PO') {
                        return true
                    }
                    if (_this.noRc) {
                        return true
                    }
                    const numericValue = `${value}`.replace(/\D/ig, '')
                    if (numericValue.length === 11) {
                        return mod1110.validate(numericValue)
                    }
                    return numericValue.length >= 9
                }
            },
            birthDate: {
                required: requiredIf(() => {
                    return _this.clientType !== 'PO'
                })
            },
            email: {
                required,
                custom (value) {
                    if (!value) {
                        return false
                    }
                    const inputAsArray = `${value}`.split(';').map(item => `${item}`.trim())
                    for (const input of inputAsArray) {
                        if (!email(input) || !input) {
                            return false
                        }
                    }
                    return true
                }
            },
            phone: {
                required,
                numberValidation (value) {
                    if (!value) {
                        return false
                    }
                    const number = parsePhoneNumberFromString(`${value}`)
                    if (number && number.isValid()) {
                        return true
                    }
                    return false
                }
            },
            phonePassword: {
                required
            },
            companyName: {
                required: requiredIf(model => {
                    return _this.clientType === 'PO'
                })
            },
            companyScope: {
                required: requiredIf(model => {
                    return _this.clientType !== 'FO'
                }),
                maxLength: maxLength(500)
            },
            companyRealScope: {
                required: requiredIf(model => {
                    return _this.clientType !== 'FO'
                })
            },
            companyForm: {
                required: requiredIf(model => {
                    return _this.clientType !== 'FO'
                })
            },
            companyRiskActivity: {
                required: requiredIf(model => {
                    return _this.clientType !== 'FO'
                })
            },
            ic: {
                required: requiredIf(model => {
                    return _this.clientType !== 'FO'
                })
            },
            countryOrigin: {
                required
            }
        }
    }
}
</script>
<style>
    .popover.popover-large {
        max-width: 80vw;
    }
</style>
