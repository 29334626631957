<script>
import ContractCheckbox from './ContractCheckbox.vue'
import ContractPar from './ContractPar.vue'
import BrokersTable from './BrokersTable.vue'
import FxHeader from './FxHeader.vue'
import ClientHeader from './ClientHeader.vue'
import AccountsTable from './AccountsTable.vue'

export default {
    name: 'ContractBase',
    props: {
        sessionData: {
            type: Object,
            required: true
        }
    },
    components: {
        ContractCheckbox,
        ContractPar,
        BrokersTable,
        FxHeader,
        ClientHeader,
        AccountsTable
    },
    computed: {
        realOwnerUndeterminable () {
            return this.clientType === 'PO' && !!this.sessionData.realOwnerUndeterminable
        },
        isCompany () {
            return this.clientType === 'PO'
        },
        isPerson () {
            return this.clientType === 'FO'
        },
        isSelfEmployed () {
            return this.clientType === 'OS'
        },
        clientType () {
            return this.sessionData.clientType
        },
        owners () {
            return (this.sessionData.clientType === 'PO') ? this.sessionData.companyPeople.filter(item => item.executive) : []
        },
        brokers () {
            return this.sessionData.companyPeople.filter(item => !item.executive)
        },
        accounts () {
            return this.sessionData.bankAccounts
        },
        realOwners () {
            return (this.sessionData.clientType === 'PO') ? this.sessionData.realOwners : []
        },
        basicData () {
            return this.sessionData.basicData
        },
        personalId () {
            return this.sessionData.personalId
        },
        legalInfo () {
            return this.sessionData.legalInfo
        },
        bankAccountsConfirmedByAffidavit () {
            return this.sessionData.bankAccountsConfirmedByAffidavit
        }
    },
    methods: {
        getIterator (section, count) {
            const items = []
            for (let i = 1; i <= count; i++) {
                items.push({ id: section + '.' + i + '.', index: i })
            }
            return items
        }
    }

}
</script>
